import styled from 'styled-components';
import { P, Icon } from '@ovotech/element';

export const InfoContainer = styled.div`
  display: flex;
`;

export const InfoText = styled(P)`
  margin-top: 0;
`;

export const InfoIcon = styled(Icon)`
  align-self: center;
  flex-shrink: 0;
`;
