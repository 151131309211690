import { PageProps } from 'gatsby';
import React from 'react';

import { ModalContextProvider } from '@contexts/ModalContext';
import { HomeRecoverOverview } from '@src/modules/HomeRecoverOverview/HomeRecoverOverview';

export const HomeRecover = (props: PageProps) => {
  return (
    <ModalContextProvider>
      <HomeRecoverOverview {...props} />
    </ModalContextProvider>
  );
};

export default HomeRecover;
