import styled from 'styled-components';
import { Margin, P, Strong } from '@ovotech/element';

import { useAppContext } from '@contexts/AppContext';
import { MixpanelEvents, Page } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import { mqSmallUp } from '@utils/mediaQuery';
import { PrimaryCTA } from '@components/PrimaryCTA';

const GreenText = styled.span`
  ${({ theme }) => `
    color: ${theme.semantic.message.branded};
  `}
`;

const BannerText = styled(P)`
  ${mqSmallUp('min-height: 80px;')}
`;

const StyledBannerWrapper = styled.div`
  padding: 24px;
  padding-top: 0;
  button {
    max-width: 300px;
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const CustomerLogInSection = () => {
  const { navigateToLogin } = useAppContext();
  const { track } = useCustomMixpanel();

  return (
    <StyledBannerWrapper>
      <BannerText>
        <Strong>Already an OVO customer?</Strong>
        <Margin vertical={1} />
        <P>
          Log in to get your{' '}
          <GreenText>
            <strong>free</strong>{' '}
          </GreenText>
          repair + our Complete cover.{' '}
        </P>
      </BannerText>
      <Margin bottom={4} />
      <Centered>
        <PrimaryCTA
          fullWidth
          variant="primary"
          onClick={() => {
            track(MixpanelEvents.BUTTON_CLICK, {
              buttonId: 'getYourFreeRepair',
              pageName: 'home-recover',
            });
            navigateToLogin(Page.HOME_RECOVER);
          }}
        >
          Get your free repair
        </PrimaryCTA>
      </Centered>
    </StyledBannerWrapper>
  );
};
