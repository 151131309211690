import { isBrowser } from '@utils/isBrowser';
export interface AnalyticsEvent {
  event?: string;
  // Remember that if these properties are not provided, GTM will use the previous value
  [p: string]: unknown | Array<unknown>;
}

interface Window {
  dataLayer?: Array<AnalyticsEvent>;
}

export class DataLayer {
  public static get dataLayerWindow() {
    if (isBrowser()) {
      return window as Window;
    }
  }

  public static get dataLayer() {
    if (DataLayer.dataLayerWindow && DataLayer.dataLayerWindow.dataLayer) {
      return DataLayer.dataLayerWindow.dataLayer;
    }
    return [];
  }

  public static push(events: AnalyticsEvent[]) {
    const dataLayer = DataLayer.dataLayer;
    dataLayer.push(...events);
  }
}
