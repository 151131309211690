import styled from 'styled-components';
import { CTAButton, Margin, P, Strong } from '@ovotech/element';

import { MixpanelEvents } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import { mqSmallUp } from '@utils/mediaQuery';

const GreenText = styled.span`
  ${({ theme }) => `
    color: ${theme.semantic.message.branded};
  `}
`;

const BannerText = styled(P)`
  ${mqSmallUp('min-height: 80px;')}
`;

const StyledBannerWrapper = styled.div`
  padding: 24px;
  padding-top: 0;
  button {
    max-width: 300px;
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

type NotCustomerTextProps = {
  freeRepair: boolean;
  onContinue: () => void;
};

export const NotCustomerText = ({ onContinue, freeRepair }: NotCustomerTextProps) => {
  const { track } = useCustomMixpanel();

  return (
    <StyledBannerWrapper>
      <BannerText>
        <strong>Not with OVO?</strong>
        <Margin vertical={1} />
        {!freeRepair ? (
          <P>
            Get your repair for{' '}
            <GreenText>
              <Strong>£79</Strong>{' '}
            </GreenText>
            when you take out our Complete cover.
          </P>
        ) : (
          <P>
            For a limited time get a{' '}
            <GreenText>
              <Strong>free</Strong>
            </GreenText>{' '}
            repair, normally £79, when you take out Complete cover.
          </P>
        )}
      </BannerText>
      <Margin vertical={4} />
      <Centered>
        <CTAButton
          fullWidth
          data-testid="repair-login-banner-continue"
          variant="secondary"
          type="button"
          onClick={() => {
            track(MixpanelEvents.BUTTON_CLICK, {
              buttonId: 'bookRepair79',
              pageName: 'home-recover',
            });
            onContinue();
          }}
        >
          {freeRepair ? 'Get your free repair' : 'Book repair for £79'}
        </CTAButton>
      </Centered>
    </StyledBannerWrapper>
  );
};
