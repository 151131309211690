import { SummaryItem } from '@entities/types';

const summaryItems: SummaryItem[] = [
  {
    id: 'annualBoilerService',
    name: 'Annual boiler service',
    icon: 'boiler-service',
    info: {
      withExcess: [
        '• A visual check of the boiler',
        '• The boiler being fired safely to identify any working faults',
        '• A flue gas analysis efficiency test',
        '• Opening up the boiler and inspecting it, if necessary',
        '• Checking the flue and ventilation are in accordance with Gas Safety (Installation and Use) Regulations',
        '• Checking and adjusting the system pressure',
        '• Cleaning the condensate trap',
        '• Visually checking and bleeding radiators if necessary',
        '• A visual check of the hot water cylinder',
        '• Confirmation in writing that the service has been carried out',
        '• Cleaning of the boiler’s filter (if you ask us to)',
      ],
      noExcess: [
        '• A visual check of the boiler',
        '• The boiler being fired safely to identify any working faults',
        '• A flue gas analysis efficiency test',
        '• Opening up the boiler and inspecting it, if necessary',
        '• Checking the flue and ventilation are in accordance with Gas Safety (Installation and Use) Regulations',
        '• Checking and adjusting the system pressure',
        '• Cleaning the condensate trap',
        '• Visually checking and bleeding radiators if necessary',
        '• A visual check of the hot water cylinder',
        '• Confirmation in writing that the service has been carried out',
        '• Cleaning of the boiler’s filter (if you ask us to)',
      ],
    },
  },
  {
    id: 'boilerHeatingSystem',
    name: 'Boiler & heating System',
    icon: 'boiler-heating',
    info: {
      withExcess: [
        '• The following items are covered as part of your policy:',
        '• Gas fired central heating boiler',
        '• Controls inc programmers, thermostats, pumps & motorised valves',
        '• Radiators and valves',
        '• System pipes and fittings',
        '• Hot water cylinder, feed and expansion tanks',
      ],
      noExcess: [
        '• The following items are covered as part of your policy:',
        '• Gas fired central heating boiler',
        '• Controls inc programmers, thermostats, pumps & motorised valves',
        '• Radiators and valves',
        '• System pipes and fittings',
        '• Hot water cylinder, feed and expansion tanks',
      ],
    },
  },
  {
    id: 'plumbing',
    name: 'Plumbing',
    icon: 'plumbing',
    info: {
      withExcess: [
        '• The following items are covered as part of your policy:',
        '• Repairs to your plumbing including hot and cold water pipes, cold water tanks & overflows',
      ],
      noExcess: [
        '• The following items are covered as part of your policy:',
        '• Repairs to your plumbing including hot and cold water pipes, cold water tanks & overflows',
      ],
    },
  },
  {
    id: 'electrics',
    name: 'Electrics',
    icon: 'electric',
    info: {
      withExcess: [
        '• The following items are covered as part of your policy:',
        '• Internal mains electric wiring',
        '• Fuse boards & circuit breakers',
        '• Sockets & switches',
      ],
      noExcess: [
        '• The following items are covered as part of your policy:',
        '• Internal mains electric wiring',
        '• Fuse boards & circuit breakers',
        '• Sockets & switches',
      ],
    },
  },
  {
    id: 'boilerReplacement',
    name: 'Boiler replacement',
    icon: 'boiler-replacement',
    info: {
      withExcess: [
        'If your boiler is up to 10 years old and cannot be repaired:',
        '• For boilers less than 7 years old at the start of your first policy, we will supply and install a new replacement boiler up to the maximum value of £2500',
        '• For boilers between 7 and 10 years old at the start of your first policy, we will make a maximum contribution of £400 towards the cost of a replacement boiler',
      ],
      noExcess: [
        'If your boiler is up to 10 years old and cannot be repaired:',
        '• For boilers less than 7 years old at the start of your first policy, we will supply and install a new replacement boiler up to the maximum value of £2500',
        '• For boilers between 7 and 10 years old at the start of your first policy, we will make a maximum contribution of £400 towards the cost of a replacement boiler',
      ],
    },
  },
  {
    id: 'gasSupplyPipe',
    name: 'Gas supply pipe',
    icon: 'gas',
    info: {
      withExcess: [
        '• We’ll cover the following:',
        '• Gas supply pipes inside your home feeding the central heating boiler and other gas appliances',
      ],
      noExcess: [
        '• We’ll cover the following:',
        '• Gas supply pipes inside your home feeding the central heating boiler and other gas appliances',
      ],
    },
  },
  {
    id: 'waterSupplyPipe',
    name: 'Water supply pipe',
    icon: 'water-supply-pipe',
    info: {
      withExcess: [
        '• The following items are covered as part of your policy:',
        '• Fresh water supply pipes within your boundary between your home and the mains supply pipes',
        '• Excavation of underground pipes',
      ],
      noExcess: [
        '• The following items are covered as part of your policy:',
        '• Fresh water supply pipes within your boundary between your home and the mains supply pipes',
        '• Excavation of underground pipes',
      ],
    },
  },
  {
    id: 'internalDrains',
    name: 'Internal drains',
    icon: 'internal-drains',
    info: {
      withExcess: [
        '• We’ll cover the following:',
        '• Blocked or leaking internal drains and waste pipes',
      ],
      noExcess: [
        '• We’ll cover the following:',
        '• Blocked or leaking internal drains and waste pipes',
      ],
    },
  },
  {
    id: 'externalDrains',
    name: 'External drains',
    icon: 'external-drains',
    info: {
      withExcess: [
        '• The following items are covered as part of your policy:',
        '• Blocked or leaking external drains within your boundary',
        '• Excavation of underground drains',
      ],
      noExcess: [
        '• The following items are covered as part of your policy:',
        '• Blocked or leaking external drains within your boundary',
        '• Excavation of underground drains',
        '• £50 excess on drainage call outs',
      ],
    },
  },
  {
    id: 'tapsAndToilets',
    name: 'Taps and toilets',
    icon: 'taps-and-toilets',
    info: {
      withExcess: [
        '• The following items are covered as part of your policy:',
        '• Leaking taps and running toilets',
        '• Ball cocks, syphons and valves',
        '• Replacement of non-ceramic tap washers',
      ],
      noExcess: [
        '• The following items are covered as part of your policy:',
        '• Leaking taps and running toilets',
        '• Ball cocks, syphons and valves',
        '• Replacement of non-ceramic tap washers',
        '• £50 excess on taps and toilets',
      ],
    },
  },
  {
    id: 'priorityHelpline',
    name: 'Priority helpline',
    icon: 'energy-advice',
    info: {
      withExcess: [
        '• Priority access to a personal claims handler through our UK call centre available 24/7 365 days a year',
      ],
      noExcess: [
        '• Priority access to a personal claims handler through our UK call centre available 24/7 365 days a year',
      ],
    },
  },
];

export const sevenDaysAWeekItems = summaryItems.filter(
  (summaryItem) =>
    summaryItem.id === 'boilerHeatingSystem' ||
    summaryItem.id === 'plumbing' ||
    summaryItem.id === 'electrics' ||
    summaryItem.id === 'internalDrains'
);

export const completePlanItems = [
  {
    id: 'unlimitedCallouts',
    name: 'Unlimited callouts',
    icon: 'boilerEngineer',
  },
  {
    id: 'partsAndLabourIncluded',
    name: 'Parts and labour included',
    icon: 'home',
  },
  {
    id: 'twentyFourSevenEmergencyHelpline',
    name: '24/7 emergency helpline',
    icon: 'energy-advice',
  },
];

export const stripBullet = (text: string) => text.replace(/•/g, '').trim();

export default summaryItems;
